import './Footer.css'
import './FooterResponsive.css'

function Footer(){
    return(
        <div class="footer">
            <p>Copyright Terelita - 2021. Todos os direitos reservados.</p>
        </div>
    )
}

export default Footer